<template>
  <div class="send-screening">
    <el-dialog title="随访筛查" :visible.sync="visible" width="500px" :close-on-click-modal="false">
      <div class="page-con">
        <el-form
          :model="dataForm"
          :rules="rules"
          ref="dataForm"
          label-width="120px"
          class="demo-dataForm"
        >
          <el-form-item label="随访名称" prop="followUpName">
            <el-input style="width:90%;" placeholder="请输入随访名称" v-model="dataForm.followUpName"></el-input>
          </el-form-item>
          <el-form-item label="随访人">{{loginName}}</el-form-item>
          <el-form-item label="随访时间" prop="followUpTime">
            <el-date-picker
              style="width:90%;"
              v-model="dataForm.followUpTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="随访类型" prop="followUpType">
            <el-select
              v-model="dataForm.followUpType"
              style="width:90%;"
              placeholder="请选择随访类型"
              clearable
            >
              <el-option
                v-for="(item,index) in followUpTypeList"
                :key="index"
                :value="item.value"
                :label="item.name"
              >{{item.name}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="分类"
            prop="contentType"
            v-if="dataForm.followUpType == '' ||dataForm.followUpType == 1"
          >
            <el-select
              v-model="dataForm.contentType"
              @change="getSec"
              style="width:90%;"
              placeholder="请选择问卷分类"
            >
              <el-option
                v-for="(item,index) in followUpClassifyList"
                :key="index"
                :value="item.value"
                :label="item.name"
              >{{item.name}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类" prop="workType" v-if="dataForm.followUpType == 2">
            <el-cascader
              style="width:90%;"
              v-model="dataForm.workType"
              :options="missionaryClassifyList"
              :props="{ checkStrictly: true,value:'value',label:'name'}"
              filterable
              :show-all-levels="false"
              clearable
            ></el-cascader>
            <!-- <el-select v-model="dataForm.workType" 
             @change='getSec'
            style="width:90%;" placeholder="请选择宣教分类">
              <el-option
                v-for="(item,index) in missionaryClassifyList"
                :key="index"
                :value="item.value"
                :label="item.name"
              >{{item.name}}</el-option>
            </el-select>-->
          </el-form-item>
          <el-form-item
            label="二级分类"
            prop="secType"
            v-if="dataForm.followUpType == 1&&dataForm.contentType"
          >
            <el-select
              v-model="dataForm.contentTypeSec"
              style="width:90%;"
              placeholder="请选择二级分类"
              clearable
            >
              <el-option
                v-for="(item,index) in secList"
                :key="index"
                :value="item.value"
                :label="item.name"
              >{{item.name}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="名称"
            prop="contentId"
            v-if="dataForm.followUpType == '' || dataForm.followUpType == 1"
          >
            <el-select v-model="dataForm.contentId" style="width:90%;" placeholder="请选择问卷名称">
              <el-option
                v-for="(item,index) in questionList"
                :key="index"
                :value="item.id"
                :label="item.tableName"
              >{{item.tableName}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="workId" v-if="dataForm.followUpType == 2">
            <el-select v-model="dataForm.workId" style="width:90%;" placeholder="请选择宣教名称">
              <el-option
                v-for="(item,index) in missionaryWorkList"
                :key="index"
                :value="item.id"
                :label="item.workName"
              >{{item.workName}}</el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label>
            <div class="chose-list-box">
              <div
                class="list-item list-item2"
                @click="removeCheckbox(item)"
                v-for="(item,index) in choseList"
                :label="item"
                :key="index"
              >
                {{item.name}}
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </el-form-item>-->
          <el-form-item class="send-screen-label" :label="'发送人员筛选'+totalCount+'人'" required>
            <div class="send-staff-list">
              <el-scrollbar
                class="custom-scrollbar"
                style="height:100%;overflow:hidden;padding:20px 0 20px 20px;"
                ref="scrollbar"
              >
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                >全选</el-checkbox>
                <el-checkbox-group v-model="checkStaff" @change="handleCheckedChange">
                  <el-checkbox
                    v-for="(item,index) in checkStaffList"
                    :label="item.patience_id"
                    :key="index"
                    style="width:80%;"
                  >{{item.patience_name}}</el-checkbox>
                </el-checkbox-group>
              </el-scrollbar>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确定筛选</el-button>
          <el-button @click="visible = false">取消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import moment from "moment";
export default {
  props: {
    dataList: {
      type: Array
    }
  },
  data() {
    return {
      visible: false,
      id: "",
      dataForm: {
        followUpName: "", //随访名称
        followUpId: "", //发送随访人id
        followUpTime: "", //随访时间
        followUpType: 1, //随访类型
        contentType: "", //内容分类
        contentId: "", //问卷id==数据采集问卷id
        hospitalId: "", //随访人医院
        deptId: "", //随访人科室
        workType: ["", "", "", "", ""], //宣教分类
        workId: "", //宣教名称
        contentTypeSec: "",
        secList: []
      },
      rules: {
        followUpName: [
          { required: true, message: "请输入随访名称", trigger: "blur" }
        ],
        followUpTime: [
          { required: true, message: "请选择随访时间", trigger: "blur" }
        ],
        followUpType: [
          { required: true, message: "请选择随访类型", trigger: "change" }
        ],
        contentType: [
          { required: true, message: "请选择问卷分类", trigger: "change" }
        ],
        contentId: [
          { required: true, message: "请选择问卷名称", trigger: "change" }
        ],
        workType: [
          { required: true, message: "请选择宣教分类", trigger: "change" }
        ],
        workId: [
          { required: true, message: "请选择宣教名称", trigger: "change" }
        ]
      },
      loginName: "", //当前登录者姓名
      followUpTypeList: [], //随访类型列表
      followUpClassifyList: [], //随访问卷分类
      questionList: [], //问卷名称列表
      missionaryClassifyList: [], //宣教分类
      missionaryWorkList: [], //宣教名称列表
      choseList: [],
      checkAll: false,
      checkStaff: [],
      checkStaffList: [],
      totalCount: 0, //发送人员人数
      isIndeterminate: true,
      flag: true //防止多次提交
    };
  },
  mounted() {
    this.dataForm.followUpTime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (localStorage.getItem("userInfo")) {
      this.loginName = JSON.parse(localStorage.getItem("userInfo")).name;
      this.dataForm.hospitalId = JSON.parse(
        localStorage.getItem("userInfo")
      ).deptPid;
      this.dataForm.deptId = JSON.parse(
        localStorage.getItem("userInfo")
      ).deptId;
    }
  },
  methods: {
    getSec(type) {
      console.log(type, "type");
      if (this.dataForm.followUpType == 1) {
        let obj = this.followUpClassifyList.find(v => v.value == type);
        console.log(obj);
        this.secList = obj.map.list;
      } else {
        let obj = this.missionaryClassifyList.find(v => v.value == type);
        console.log(obj);
        this.secList = obj.map.list;
      }
    },
    init(ids) {
      let _this = this;
      _this.visible = true;
      _this.flag = true;
      _this.$nextTick(() => {
        _this.$refs["dataForm"].resetFields();
      });
      if (ids.length) {
        _this.checkStaff = ids;
      } else {
        _this.checkStaff = [];
      }
      console.log(_this.checkStaff)
      _this.getDataList();
      _this.getDictList(1, "随访类型");
      _this.getDictList(2, "表单类别");
      _this.getDictList(3, "宣教分类");
      // _this.getQuestionList();
      // _this.getMissionaryWorkList();
    },
    removeCheckbox(item) {
      this.choseList.forEach((ele, index) => {
        if (item.id == ele.id) {
          this.choseList.splice(index, 1);
        }
      });
    },
    //全选
    handleCheckAllChange(val) {
      this.checkStaff = val;
      this.isIndeterminate = false;
      this.checkAll = val;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkStaffList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.checkStaffList.length;
      this.checkStaff = value;
    },
    // 获取发送人员列表
    getDataList() {
      let that = this;
      let params = {
        pageSize: 10000,
        pageNo: 1,
        type: 1,
        teamsId: localStorage.currentTeam
          ? JSON.parse(localStorage.currentTeam).id
          : ""
      };
      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl(
            "/teamsPatience/getTeamsPatiencesGroupByPatienceId"
          ),
          method: "get",
          params: params
        })
        .then(({ data }) => {
          if (data.status) {
            that.checkStaffList = data.data;
            that.totalCount = data.data.length;
            if (that.totalCount == that.checkStaff.length) {
              that.checkAll = true;
              that.isIndeterminate = false;
            }
          }
        });
    },
    // 获取字典
    getDictList(type, typeName) {
      let _this = this;
      let url;
      if (typeName == "随访类型") {
        url = _this.$http.adornUrl("/dict/getSysDicts?type=" + typeName);
      } else {
        url = _this.$http.adornUrl(
          "/dict/getSysDicts?type=" +
            typeName +
            "&teamsId=" +
            JSON.parse(localStorage.currentTeam).id
        );
      }
      _this
        .$http({
          url: url,
          method: "get"
        })
        .then(({ data }) => {
          if (type == 1) {
            _this.followUpTypeList = data.data;
          } else if (type == 2) {
            _this.followUpClassifyList = data.data;
          } else if (type == 3) {
            for (var i = 0; i < data.data.length; i++) {
              data.data[i].isEdit = false;
              data.data[i].children = data.data[i].map.list;
              // console.log(data.data[i].children,'data.data[i].children');
              for (var k = 0; k < data.data[i].children.length; k++) {
                if (
                  data.data[i].children[k].map &&
                  data.data[i].children[k].map.list.length
                ) {
                  data.data[i].children[k].children =
                    data.data[i].children[k].map.list;
                  for (
                    var j = 0;
                    j < data.data[i].children[k].children.length;
                    j++
                  ) {
                    if (
                      data.data[i].children[k].children[j].map &&
                      data.data[i].children[k].children[j].map.list.length
                    ) {
                      data.data[i].children[k].children[j].children =
                        data.data[i].children[k].children[j].map.list;
                      for (
                        var m = 0;
                        m <
                        data.data[i].children[k].children[j].children.length;
                        m++
                      ) {
                        if (
                          data.data[i].children[k].children[j].children[m]
                            .map &&
                          data.data[i].children[k].children[j].children[m].map
                            .list.length
                        ) {
                          data.data[i].children[k].children[j].children[
                            m
                          ].children =
                            data.data[i].children[k].children[j].children[
                              m
                            ].map.list;
                        } else {
                          data.data[i].children[k].children[j].children[
                            m
                          ].children = undefined;
                        }
                      }
                    } else {
                      data.data[i].children[k].children[j].children = undefined;
                    }
                  }
                } else {
                  data.data[i].children[k].children = undefined;
                }
              }
            }
            _this.missionaryClassifyList = data.data;
          }
        });
    },
    // 获取问卷名称
    getQuestionList() {
      let _this = this;
      let unionId = "";
      if (localStorage.userInfo) {
        unionId = JSON.parse(localStorage.userInfo).doctorUnionId;
      }
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let userInfo = {};
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      let params = {
        intParam1: 10000,
        intParam2: 1,
        // hospitalId: userInfo.deptPid,
        stringParam3: teamsId,
        intParam7: 1,
      };
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/vfTableInfo/list"),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.questionList = data.data;
          }
        });
    },
    // 获取宣教名称
    getMissionaryWorkList() {
      let _this = this;
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {
        intParam1: 10000,
        intParam2: 1,
        stringParam2: _this.dataForm.workType[0],
        stringParam3: _this.dataForm.workType[1],
        stringParam4: _this.dataForm.workType[2],
        stringParam5: _this.dataForm.workType[3],
        stringParam6: _this.dataForm.workType[4],
        stringParam8: teamsId,
        stringParam9: enertyId
      };
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/missionaryWork/list"),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.missionaryWorkList = data.data;
          }
        });
    },
    // 提交随访信息
    submitForm() {
      let _this = this;
      let item;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
        _this.dataForm.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (_this.dataForm.followUpType == 1) {
        item = this.questionList.find(v => v.id == this.dataForm.contentId);
        _this.dataForm.contentTypeSec = item.contentTypeSec;

        _this.dataForm.contentType = _this.dataForm.contentType;
        _this.dataForm.contentId = _this.dataForm.contentId;
      } else {
        item = this.missionaryWorkList.find(v => v.id == this.dataForm.workId);
        _this.dataForm.contentType = _this.dataForm.workType[0];
        _this.dataForm.contentTypeSec = _this.dataForm.workType[1];
        _this.dataForm.contentTypeThree = _this.dataForm.workType[2];
        _this.dataForm.contentTypeFour = _this.dataForm.workType[3];
        _this.dataForm.contentTypeFive = _this.dataForm.workType[4];
        _this.dataForm.workType = _this.dataForm.contentType;
        _this.dataForm.contentId = _this.dataForm.workId;
      }
      if (this.checkAll) {
        this.checkStaff = this.checkStaffList.map(v => v.patience_id);
      }
      _this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (!_this.checkStaff.length) {
            _this.$message.error("请选择发送人员");
            return;
          } else {
            _this.dataForm.followUpId = _this.checkStaff.join(",");
          }

          if (_this.flag) {
            _this.flag = false;
            // console.log('done');
            _this
              .$http({
                url: _this.$http.adornUrl("/followUp/add"),
                method: "post",
                data: qs.stringify(_this.dataForm)
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.$message({
                    message: data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.visible = false;
                      _this.flag = true;
                      _this.$emit("refreshDataList", 1);
                      if (data.data.id) {
                        this.submitVfRelationInfo(data.data.id)
                      }
                    }
                  });
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },
    //  发放任务患者和表单关联关系
    submitVfRelationInfo(taskId) {
      let _this = this;
      let params = {
        infoId: _this.dataForm.contentId,
        patientId:  _this.checkStaff.length?_this.checkStaff.join(','):"",
        taskId: taskId,
        type: 1,
      }
      if (localStorage.currentTeam) {
        params.teamId = JSON.parse(localStorage.currentTeam).id;
      }
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/vfRelationInfo/add"),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
          }
        });
    }
  },
  watch: {
    "dataForm.contentType"() {
      this.getQuestionList();
    },
    "dataForm.workType"() {
      this.getMissionaryWorkList();
    },
    "dataForm.contentTypeSec"() {
      if (this.dataForm.followUpType == 1) {
        this.getQuestionList();
      } else {
        this.getMissionaryWorkList();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.send-screening {
  .chose-list-box {
    overflow: hidden;
    .list-item {
      padding: 0 20px;
      border: 1px solid #dcdfe6;
      margin-left: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
      float: left;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
      &.list-item2 {
        background: #409eff;
        color: #fff;
        .el-icon-delete {
          margin-left: 10px;
        }
      }
    }
  }
  .send-staff-list {
    width: 90%;
    height: 200px;
    border: 1px solid #dcdfe6;
  }
}
</style>