<template>
  <div class="add-or-update">
    <el-dialog
      :title="id?'编辑':'新增' + '用户筛查'"
      :visible.sync="visible"
      width="700px"
      :close-on-click-modal="false"
    >
      <div class="form-con">
        <el-form
          :model="dataForm"
          :rules="rules"
          ref="dataForm"
          label-width="120px"
          class="demo-dataForm"
        >
          <div style="font-weight:bold;padding:0 10px 10px 10px;">个人信息</div>
          <el-form-item
            :label="item.name"
            :prop="item.ename"
            v-for="(item,index) in selectStringList"
            :key="index"
          >
            <el-input
              v-if="item.type == 1"
              style="width: 70%"
              :placeholder="'请输入'+item.name"
              v-model="dataForm[item.ename]"
              :maxlength="item.ename == 'patienceIdCard'?18:''"
              :type="item.ename=='patienceAge'?'number':''"
              :disabled="((item.ename == 'patienceIdCard' && id) ? true : false)
              ||(item.ename == 'patienceAge'&&ageFlag)
              ||(item.ename == 'patienceBirth'&&ageFlag)"
            ></el-input>
            <el-radio-group v-if="item.ename == 'patienceSex'" v-model="dataForm.patienceSex">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
            <select-area v-if="item.type == 6" v-model="cityInfo" :myData="cityInfo"></select-area>
          </el-form-item>
          <div style="font-weight:bold;padding:0 10px 10px 10px;">病患信息</div>
          <el-form-item
            :label="item.name"
            :prop="item.ename"
            v-for="(item,index) in selectStringList2"
            :key="index"
          >
            <el-select
              v-if="item.ename == 'source'"
              v-model="dataForm.mbglResourceType"
              placeholder="请选择来源"
              style="width:70%;"
            >
              <el-option
                v-for="(ele,index) in sourceList"
                :key="index"
                :label="ele.name"
                :value="ele.value"
              ></el-option>
            </el-select>
            <el-select
              v-if="item.ename == 'reason'"
              v-model="dataForm.mbglReasonType"
              placeholder="设为筛查原因"
              style="width:70%;"
              multiple
            >
              <el-option
                v-for="(ele,index) in reasonList"
                :key="index"
                :label="ele.name"
                :value="ele.name"
              ></el-option>
            </el-select>
            <el-input
              v-if="item.type == 3"
              style="width: 70%"
              :placeholder="'请输入'+item.name"
              v-model="dataForm[item.ename]"
              type="textarea"
              rows="5"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
var regPhone = /^(13[0-9]|14[14-8]|15[0-35-9]|16[6]|17[0-8]|18[0-9]|19[0-9])[0-9]{8}$/;
var regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
import SelectArea from "@/components/selectArea";
import qs from "qs";
import moment from "moment";

export default {
  components: {
    SelectArea
  },
  watch: {
    "dataForm.patienceIdCard": function(v) {
      console.log(v, "watch");
      var len = (v + "").length;
      console.log(len);
      var strBirthday = "";
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          v.substr(6, 4) + "/" + v.substr(10, 2) + "/" + v.substr(12, 2);
      }
      if (len == 15) {
        strBirthday =
          "19" + v.substr(6, 2) + "/" + v.substr(8, 2) + "/" + v.substr(10, 2);
      }
      //时间字符串里，必须是“/”
      var birthDate = new Date(strBirthday);
      let birth = moment(birthDate).format("YYYY-MM-DD");
      // console.log(birthDate,'birthDate');
      var nowDateTime = new Date();
      var age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      console.log(age, "age");
      if (age) {
        console.log(this.dataForm, "dataForm");
        this.dataForm.patienceAge = age;
        this.dataForm.patienceBirth = birth;

        this.ageFlag = true;
      }
    }
  },
  data() {
    var validateIdCard = (rule, value, callback) => {
      // console.log(value)
      if (value === "") {
        callback(new Error("请输入身份证号"));
      } else if (!regIdCard.test(value)) {
        callback(new Error("身份证格式不正确"));
      } else {
        if (!this.id) {
          this.idCardIsExist(value);
        }
        callback();
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else if (!regPhone.test(value)) {
        callback(new Error("联系方式格式不正确"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      ageFlag: false,
      id: "",
      info: {},
      info2: {},
      dataForm: {
        patienceIdCard: "", //身份证号
        patienceName: "", //姓名
        patienceSex: "1", //性别
        patienceAge: "", //年龄
        patienceBirth: "",
        patienceLinkTel: "", //联系方式
        mbglResourceType: "", //来源
        mbglReasonType: [] //原因
      },
      rules: {
        patienceIdCard: [
          { required: true, validator: validateIdCard, trigger: "blur" }
        ],
        patienceName: [
          { required: true, message: "请输入姓名", trigger: "blur" }
        ],
        patienceLinkTel: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur"
          }
        ]
      },
      selectStringList: [
        { name: "身份证号", value: 4, ename: "patienceIdCard", type: 1 },
        { name: "姓名", value: 1, ename: "patienceName", type: 1 },
        { name: "性别", value: 3, ename: "patienceSex", type: 5 },
        { name: "联系方式", value: 2, ename: "patienceLinkTel", type: 1 },
        { name: "出生日期", value: 6, ename: "patienceBirth", type: 1 },
        { name: "年龄", value: 5, ename: "patienceAge", type: 1 },
        { name: "地区", value: 5, ename: "age", type: 6 }
      ],
      selectStringList2: [
        { name: "来源", value: 6, ename: "source", type: 2 },
        { name: "设为筛查原因", value: 7, ename: "reason", type: 2 },
        { name: "病况补充", value: 12, ename: "mbglDescription", type: 3 },
        { name: "备注", value: 17, ename: "mbglContent", type: 3 }
      ],
      cityInfo: {}, //选择省市区
      sourceList: [], //来源
      reasonList: [], //原因
      flag: true //防止重复提交
    };
  },
  methods: {
    init(rows) {
      let _this = this;
      _this.flag = true;
      _this.ageFlag = false;
      _this.visible = true;
      _this.id = "";
      _this.cityInfo = {};
      _this.dataForm.mbglResourceType = "";
      _this.dataForm.mbglReasonType = "";
      _this.dataForm = {
        patienceIdCard: "", //身份证号
        patienceName: "", //姓名
        patienceSex: "1", //性别
        patienceAge: "", //年龄
        patienceLinkTel: "", //联系方式
        patienceBirth: "", //生日
        mbglResourceType: "", //来源
        mbglReasonType: [], //原因
        mbglDescription: "",
        mbglContent: ""
      };
      _this.$nextTick(() => {
        _this.$refs["dataForm"].resetFields();
      });
      if (rows.id) {
        _this.id = rows.id;
        _this.getInfo();
      }
      _this.getSourceList();
      _this.getReasonList();
    },
    getInfo() {
      let _this = this;
      let params = {
        stringParam1: _this.id
      };
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/teamsPatience/info"),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.info = data.data.patience;
            _this.info2 = data.data;
            _this.dataForm.patienceIdCard = data.data.patience.patienceIdCard;
            _this.dataForm.patienceName = data.data.patience.patienceName;
            _this.dataForm.patienceBirth = data.data.patience.patienceBirth;
            _this.dataForm.patienceAge = data.data.patience.patienceAge;
            _this.dataForm.patienceLinkTel = data.data.patience.patienceLinkTel;
            _this.cityInfo = {
              sheng: data.data.patience.patientProvince,
              shengName: data.data.patience.provinceName,
              shi: data.data.patience.patientCity,
              shiName: data.data.patience.cityName,
              qu: data.data.patience.patientCounty,
              quName: data.data.patience.countyName
            };
            _this.dataForm.patienceSex = data.data.patience.patienceSex.toString();
            _this.dataForm.mbglResourceType = data.data.mbglResourceType;
            if (data.data.mbglReasonType!='') {
              // console.log();
              _this.dataForm.mbglReasonType = data.data.mbglReasonType.split(
                ","
              );
            }
            _this.dataForm.mbglDescription = data.data.mbglDescription;
            _this.dataForm.mbglContent = data.data.mbglContent;
          }
        });
    },
    //根据身份证显示已存在患者信息
    idCardIsExist() {
      let _this = this;
      let params = {
        stringParam1: _this.dataForm.patienceIdCard
      };
      _this
        .$httpAes({
       

          url: _this.$http.adornUrl("/patience/list"),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status && data.data.length) {
            let res = data.data[0];
            _this.dataForm.patienceIdCard = res.patienceIdCard;
            _this.dataForm.patienceId = res.id;
            _this.dataForm.patienceName = res.patienceName;
            _this.dataForm.patienceAge = _this.dataForm.patienceAge
              ? _this.dataForm.patienceAge
              : res.patienceAge;
            _this.dataForm.patienceBirth = _this.dataForm.patienceBirth
              ? _this.dataForm.patienceBirth
              : res.patienceBirth;
            _this.dataForm.patienceLinkTel = res.patienceLinkTel;
            _this.cityInfo = {
              sheng: res.patientProvince,
              shengName: res.provinceName,
              shi: res.patientCity,
              shiName: res.cityName,
              qu: res.patientCounty,
              quName: res.countyName
            };
            _this.dataForm.patienceSex = res.patienceSex.toString();
          } else {
            _this.dataForm.patienceId = "";
            _this.dataForm.patienceName = "";
            // _this.dataForm.patienceAge = "";
            _this.dataForm.patienceLinkTel = "";
            _this.dataForm.patienceSex = "1";
            _this.cityInfo = {};
          }
        });
    },
    // 获取来源列表
    getSourceList() {
      let _this = this;
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/dict/list?type=来源"),
          method: "post",
		  data: {stringParam1: '来源'},
        })
        .then(({ data }) => {
          _this.sourceList = data.data;
        });
    },
    // 获取来源列表
    getReasonList() {
      let _this = this;
      _this
        .$httpAes({
          url: _this.$http.adornUrl("/dict/list?type=筛查原因"),
          method: "post",
		  data:{
		    stringParam1:'筛查原因'
		  }
        })
        .then(({ data }) => {
          _this.reasonList = data.data;
        });
    },
    // 提交个人信息
    submitForm() {
      let _this = this;
      let params = {};
      if (_this.id) {
        params = _this.info;
      }
      if (_this.dataForm.patienceId) {
        params.id = _this.dataForm.patienceId;
      }
      params.patienceIdCard = _this.dataForm.patienceIdCard;
      params.patienceName = _this.dataForm.patienceName;
      params.patienceAge = _this.dataForm.patienceAge;
      params.patienceLinkTel = _this.dataForm.patienceLinkTel;
      params.patienceSex = _this.dataForm.patienceSex;
      params.patienceBirth = _this.dataForm.patienceBirth;

      if (_this.cityInfo) {
        params.patientProvince = _this.cityInfo.sheng;
        params.provinceName = _this.cityInfo.shengName;
        params.patientCity = _this.cityInfo.shi;
        params.cityName = _this.cityInfo.shiName;
        params.patientCounty = _this.cityInfo.qu;
        params.countyName = _this.cityInfo.quName;
      }
      // console.log(params);
      // return;
      _this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (_this.flag) {
            _this.flag = false;
            // return;
            _this
              .$httpAes({
                url: _this.$http.adornUrl(
                  params.id ? "/patience/update" : "/patience/add"
                ),
                method: "post",
                data: params
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.submitPatienceInfo(data.data.id);
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },
    // 提交病患信息
    submitPatienceInfo(patienceId) {
      let _this = this;
      let teamsId = "";
      let enertyId = "";
      let hospitalId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (localStorage.userInfo) {
        hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }
      let params = {
        teamsId: teamsId,
        enertyId: enertyId,
        hospitalId: hospitalId,
        patienceId: patienceId,
        mbglResourceType: _this.dataForm.mbglResourceType,
        mbglDescription: _this.dataForm.mbglDescription,
        mbglContent: _this.dataForm.mbglContent,
        type: 1
      };
      if (
        _this.dataForm.mbglReasonType &&
        _this.dataForm.mbglReasonType.length
      ) {
        params.mbglReasonType = _this.dataForm.mbglReasonType.join(",");
      } else {
        params.mbglReasonType = "";
      }
      if (_this.id) {
        params = _this.info2;
        params.mbglResourceType = _this.dataForm.mbglResourceType;
        params.mbglDescription = _this.dataForm.mbglDescription;
        params.mbglContent = _this.dataForm.mbglContent;
        if (
          _this.dataForm.mbglReasonType &&
          _this.dataForm.mbglReasonType.length
        ) {
          params.mbglReasonType = _this.dataForm.mbglReasonType.join(",");
        } else {
          params.mbglReasonType = "";
        }
        this.$delete(params, "followUps");
        this.$delete(params, "patience");
        this.$delete(params, "patiencePrograms");
        this.$delete(params, "sysPics");
        params.id = _this.id;
      }
      // console.log(params);
      // return;
      _this
        .$httpAes({
          url: _this.$http.adornUrl(
            _this.id ? "/teamsPatience/update" : "/teamsPatience/add"
          ),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.visible = false;
                _this.flag = true;
                _this.$emit("refreshDataList");
              }
            });
          } else {
            _this.$message.error(data.msg);
            _this.flag = true;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>